import React, { useEffect, useState, props } from "react";
import { useNavigate } from "react-router-dom";
import algoliasearch from "algoliasearch";
import {InstantSearch, SearchBox, Hits} from "react-instantsearch-dom"
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle, Subheading } from "components/misc/Headings.js";
import { db } from "services/firebaseConfig";
import { collection, getDocs, query, where } from "firebase/firestore";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import ModalDelete from "components/features/ModalDelete";
import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";


const Container = tw.div`relative `;
const searchClient = algoliasearch(
    'D6RUSBFJB5',
    '9e94f9fdaffd4f5140312c52a836f5d7'
  );
  const searchIndex = searchClient.initIndex('curriculos')
const Content = tw.div`max-w-screen-xl min-h-screen80 mx-auto py-20 lg:py-24 bg-gray-200`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;
const Row = styled.div`
${tw`flex flex-col items-center bg-gray-700 hover:bg-gray-800 lg:items-stretch overflow-hidden lg:flex-row mt-1  transition duration-200   flex-wrap`}
  div{
    ${tw`p-2 border-r-white text-white md:border-r-2 `}
  }
  .colunaNome{
    ${tw`lg:w-1/4 pl-5 `}
  }
   
    .colunaFunctions{
    ${tw`lg:w-1/4 p-0 border-r-0`}
  }
  .colunaFuncao{
    ${tw`lg:w-1/6 `}
  }
  .colunaIcon{
    ${tw`lg:w-1/4 cursor-pointer font-bold md:font-normal `}
  }
  .bgGreen {
    ${tw`bg-green-600`}
  }
      .rowP0 {
    ${tw`p-0 m-0 h-full border-r-0`}
  }
    .status {
    ${tw`w-6 h-6 border-2 border-gray-700 rounded-3xl`}
  }
    .colunaStatus {
    ${tw`text-center bg-gray-200 border-r-0 border-l-2`}
  }
    .acoesHeader {
    ${tw`text-center  lg:w-3/4`}
  }
    .Visualizado {
    ${tw`bg-blue-700 `}
  }
    .Selecionado {
    ${tw`bg-green-700 `}
  }
    .Entrevistado {
    ${tw`bg-yellow-400 `}
  }
    .Desconsiderado {
    ${tw`bg-red-700 `}
  }
  
`;
const RowTitle = styled.div`
${tw`md:flex flex-col items-center hidden  lg:items-stretch overflow-hidden lg:flex-row mt-1   flex-wrap`}
  div{
    ${tw`p-2 border-r-white text-white border-r-2 `}
  }
  .titleNome{
    ${tw`lg:w-1/4 pl-5 bg-gray-800 font-light `}
  }
  
  .colunaFuncao{
    ${tw`lg:w-1/6 `}
  }
    .titleFunctions{
    ${tw`lg:w-1/4 p-0 border-r-0`}
  }
    .rowP0 {
    ${tw`p-0 m-0  h-full border-r-0 bg-gray-200 hover:bg-gray-200`}
  }
    .acoesHeader {
    ${tw`text-left bg-gray-800  lg:w-3/4`}
  }
  
  
`;
const TitleSm = tw.span`md:hidden`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm flex flex-col h-full`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-64 rounded rounded-b-none`
]);

const Details = tw.div`p-6 rounded border-2 border-t-0 rounded-t-none border-dashed border-primary-100 flex-1 flex flex-col items-center text-center lg:block lg:text-left`;
const MetaContainer = tw.div`flex items-center`;
const Meta = styled.div`
  ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
  }
`;

const Title = tw.h5`mt-4 leading-snug font-bold text-sm`;
const Description = tw.p`mt-2 text-sm text-secondary-100`;
const Link = styled(PrimaryButtonBase).attrs({as: "a"})`
  ${tw`inline-block mt-4 text-sm font-semibold`}
`
const Actions = styled.div`
  ${tw`relative my-10 max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-gray-600 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-gray-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-gray-900 transition duration-300`}
  }
`;
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default ({
  subheading = "Currículos",
  heading = <>Gerenciar Currículos</>,
  description = "Some amazing blog posts that are written by even more amazing people.",
  curriculoAtual,
  setCurriculoAtual
  

}) => {
  const [cDelete, setcDelete] = useState(false)
  const [novaBusca, setNovaBusca] = useState(false);
  useEffect(()=>{
    handleSearch(busca)
  }, [] )

  useEffect(()=>{
    if(novaBusca){
      handleSearch(busca)
      setNovaBusca(false)
    }
  }, [novaBusca])


  const [busca, setBusca] = useState("");
  const [modal, setModal] = useState("");
  const [modalNome, setModalNome] = useState("");
  
  const [resultadoBusca, setResultadoBusca] = useState([])
    const navigate = useNavigate();
    const [curriculos, setCurriculos] = useState([]);
    const curriculosCollectionRef = collection(db, "curriculos")
    const handleSearch = async (queryText) => {
      try {
        const result = await searchIndex.search(queryText)
        setResultadoBusca(result.hits)
      } catch (error){
console.log(error)
      }
    }

    
    
  
  return (

    <>{cDelete && (<ModalDelete setcDelete={setcDelete} nome={modalNome} novaBusca={novaBusca} setNovaBusca={setNovaBusca} tipo="curri" uid={modal}></ModalDelete>)}
    <Container>
     
      <Content>
        <InstantSearch searchClient={searchClient} indexName="curriculos">

      <HeadingInfoContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <HeadingTitle>{heading}</HeadingTitle>
          <Actions>
              <input type="text" onKeyUp={()=>{handleSearch(busca)}} placeholder="Digite sua Busca" onChange={(e)=>{setBusca(e.target.value)}} value={busca} />
              <button onClick={()=>{handleSearch(busca)}}>Filtrar</button>
            </Actions>
          
        </HeadingInfoContainer>
        <RowTitle >
    <div className="titleNome">Nome</div>
  
    <div className="titleNome">Email</div>
    <div className="titleNome">Atuação Principal</div>
    <div className="titleFunctions"><Row className="rowP0"><div className="acoesHeader">Ações</div></Row></div>
    
   </RowTitle>
        
          {resultadoBusca.map((curri, index) => (
            <Row key={index}>
          <div className="colunaNome"><TitleSm>Nome: </TitleSm>{curri.nome}</div>
          
          <div className="colunaNome"><TitleSm>Email: </TitleSm>{curri.email}</div>
          <div className="colunaNome"><TitleSm>Atuação: </TitleSm>{curri.atuacao1}</div>
          <div className="colunaFunctions">
            <Row className="rowP0">
          <div className={`colunaIcon`} onClick={()=>{setcDelete(true);setModal(curri.useruid);setModalNome(curri.nome)}}>Apagar</div>
                    <div className={`colunaIcon `} onClick={(e) => {
                    e.preventDefault()                   
                    navigate(`/alterarCurriculo/${curri.useruid}`)
                  }}>Editar</div>
          <div className={`colunaIcon `} onClick={(e) => {
                    e.preventDefault()                   
                    navigate(`/verCurriculo/${curri.useruid}`)
                  }}>Ver</div>
                  <div className={`colunaIcon colunaStatus`}><div className={`status ${curri.state}`}></div></div>
                  </Row>
                  </div>
         </Row>
            
                ))}
                <RowTitle >
   
    
   </RowTitle>
                {/*<Hits hitComponent={Hit} ></Hits>*/}
       
       </InstantSearch>
      </Content>
     
    </Container></>
  );





};/*
const Hit = ({hit})=>{
    
    return (
    <Row >
      {cDelete && <ModalDelete setcDelete={setcDelete} tipo="curri" uid={hit.useruid}></ModalDelete>}
    <div className="colunaNome">{hit.nome}</div>
    <div className="colunaFuncao">Cargo</div>
    <div className="colunaFuncao">{hit.email}</div>
    <div className="colunaFuncao">{hit.cpf}</div>
    <div className="colunaIcon" onClick={()=>{setcDelete(true)}}>Apagar</div>
    <div className="colunaIcon" onClick={(e) => {
                    e.preventDefault()                   
                    navigate(`/alterarCurriculo/${hit.useruid}`)
                  }} >Editar</div>
    <div className="colunaIcon" onClick={(e) => {
                    e.preventDefault()                   
                    navigate(`/verCurriculo/${hit.useruid}`)
                  }} >Ver</div>
   </Row>)
}*/